export enum RecordingStatus {
    NOT_PLAYED = 0,
    PLAYING = 1,
    PLAYED = 2,
}

export enum FamilyRecordingAttribute {
    STATUS = "status",
    UI_NAME = "ui_name",
    PLAYED_COUNTER = "playedCounter"
}
export interface RecordingsObjModel {
    familyRecordings: FamilyRecordingModel[];
    generalRecordings: GeneralRecordingModel[];
    slots: RecordingSlotsModel[];
    preCommunicationSlot: PreCommunicationSlotModel;
    familyRecordingPending: boolean;
    generalRecordingPending: boolean;
    slotsPending: boolean;
}

export const NOT_PLAYING = -1;

export interface FamilyRecordingModel {
    id: string;
    count: number;
    creation_date: string;
    duration: string;
    file_name: string;
    status: RecordingStatus;
    tenant_id: string;
    ui_name: string;
    presigned_url: string;
    patient_id: string;
    playedCounter?: number;
}

export interface GeneralRecordingModel {
    id: string;
    creation_date: number;
    duration: string;
    name: string;
    path: string[];
    status: number;
    patient_id: string;
    links?: any[];
}

export interface RecordingSlotsModel {
    patient_id: string;
    recording_id: string;
    creation_date: number;
    last_update: number;
    number: number;
    recording_name?: string;
    type?: string;
}

export interface PreCommunicationSlotModel {
    patient_id: string;
    recording_id: string;
    creation_date: number;
    last_update: number;
    name: string;
    recording_name?: string;
    type?: string;
}

export interface Recordings {
    family_recordings: FamilyRecordingModel[];
    general_recordings: GeneralRecordingModel[];
}
