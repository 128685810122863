import moment, {Moment} from "moment/moment";
import { REPEAT_TYPE, RepeatEvent, SchedulerEvent, WEEK_DAYS } from "src/models/scheduler.model";

const MIDDLE_DATE = 15;

export const sortEventTimeStrings = (events: SchedulerEvent[]): SchedulerEvent[] =>
    events.sort((a, b) => {
        const momentA = moment(a.time, "HH:mm");
        const momentB = moment(b.time, "HH:mm");

        return momentA.diff(momentB);
    });

export const findRowWithCurrentDate = (arr: number[][], date: number): number | null => {
    let startIndex = 0;

    if (date > MIDDLE_DATE && arr[0].includes(date)) {
        startIndex = arr[0].indexOf(date) + 1;
    }

    for (let i = startIndex; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
            if (arr[i][j] === date) {
                return i;
            }
        }
    }

    return null;
};

const isDateSameOrAfterSelectedDate = (year: number, month: number, day: number, startDate: Moment): boolean => {
    const generatedDate = moment([year, month, day]);

    return generatedDate.startOf('day').isSameOrAfter(startDate.startOf('day'));
}

export const getUpdatedEvents = (groupedEvents: any, repeatEvents: RepeatEvent[], year: number, month: number, day: number) => {
    let eventsArray: SchedulerEvent[] = [];

    if (groupedEvents[year] && groupedEvents[year][month] && groupedEvents[year][month][day]) {
        eventsArray = groupedEvents[year][month][day];
    }

    repeatEvents.forEach((repeatEvent: RepeatEvent) => {
        if (isDateSameOrAfterSelectedDate(year, month, day, repeatEvent.startDate)) {
            if (
                (repeatEvent.reapeatType == REPEAT_TYPE.WEEKLY &&
                    repeatEvent.repeatDays.includes(moment([year, month, day]).format("dddd") as WEEK_DAYS)) ||
                repeatEvent.reapeatType == REPEAT_TYPE.DAILY
            ) {
                eventsArray.push(repeatEvent.event);
            }
        }
    });

    return eventsArray
};

export const isTodayUsingDay = (year: number, month: number, date: number) => {
    const currentDate = moment();

    return (currentDate.year() == year && currentDate.month() == month && currentDate.date() == date);
};

function isDateSameOrAfterCurrentDate(startDate: Date): boolean {
    let timestamp;
    if(typeof startDate === "string") {
        timestamp = new Date(startDate).getTime();
    } else {
        timestamp = startDate as unknown as number * 1000;
    }
    return moment(timestamp).startOf('day').isSameOrAfter(moment().startOf('day'));
}

export const groupByYearAndMonth = (events: any[]) => {
    let eventsMap: any = {};
    let repeatEvents: RepeatEvent[] = [];

    events.forEach((event: SchedulerEvent) => {
        if (isDateSameOrAfterCurrentDate(event?.startDate) || event?.repeat.isActive) {
            let timestamp;
            if(typeof event.startDate === "string") {
                const dateObj = new Date(event.startDate)
                timestamp = new Date(isTimestampInSeconds(dateObj.getTime()) ? dateObj.getTime() * 1000 : dateObj.getTime());
            } else {
                timestamp = event.startDate as unknown as number * 1000;
            }
            const date = new Date(timestamp); // multiply by 1000 to convert to milliseconds
            let year = date.getFullYear();
            if (!eventsMap[year]) {
                eventsMap[year] = {};
            }
            const month = date.getMonth();
            if (!eventsMap[year][month]) {
                eventsMap[year][month] = {};
            }
            const day = date.getDate();
            if (!eventsMap[year][month][day]) {
                eventsMap[year][month][day] = [];
            }
            if (!event?.repeat?.isActive) {
                eventsMap[year][month][day].push(event);
            } else {
                const repeatEvent: RepeatEvent = {
                    event: event,
                    startDate: moment(event.startDate),
                    reapeatType: event?.repeat?.type,
                    repeatDays: event?.repeat?.days,
                };

                repeatEvents.push(repeatEvent);
            }
        }
    });

    return { eventsMap, repeatEvents };
};

const isTimestampInSeconds = (timestamp: number): boolean => {
    return timestamp < 999999999999;  // Timestamps in seconds before year 2001
};
