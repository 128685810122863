import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { Field, InjectedFormProps, reduxForm, SubmissionError, formValueSelector } from "redux-form";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { StoreState } from "src/reducers";
import { updateToast } from "src/actions";
import { addSchedulerEvent, updateSchedulerEvent, deleteSchedulerEvent } from "src/actions/schedulerEvent.action";
import { CommunicationIcon } from "src/icons";
import { REPEAT_TYPE, SchedulerEvent } from "src/models/scheduler.model";
import { ActiveCommunicationType } from "src/models/device.model";
import { ToastModel, TOAST_SEVERITY } from "src/models/toast.model";
import { useCustomTranslation } from "src/hooks/useCustomTranslation";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";
import { MedDropDown } from "../customComponents/MedDropDown";
import { MedCalendar } from "../customComponents/MedCalendar";
import { MedRadioButtons } from "../customComponents/MedRadioButtons";
import { MedCheckboxes } from "../customComponents/MedCheckboxes";
import { communicationTypes, repeatOptions, SLOTS, WEEK_DAYS_OPTIONS } from "./constants";
import { createSchedulerObj, getCurrentDay, getInitialValues, validateForm, isDateInThePast, getTitle } from "./utils";
import { QuestionModel } from "src/models/question.model";

const FORM_NAME = 'scheduler_event_form'

interface CallbackResult {
    status: boolean;
    error?: any
}

const {NONE,Q_AND_A, GENERAL_MESSAGE } = ActiveCommunicationType

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, Action>) => {
    return {
        addSchedulerEvent: (eventItem: SchedulerEvent, callBack: (result: CallbackResult) => void) => {
            dispatch(addSchedulerEvent(eventItem, callBack));
        },
        updateSchedulerEvent: (eventItem: SchedulerEvent, callBack: (result: CallbackResult) => void) => {
            dispatch(updateSchedulerEvent(eventItem, callBack));
        },
        deleteSchedulerEvent: (id: string, callBack: (result: CallbackResult) => void) => {
            dispatch(deleteSchedulerEvent(id, callBack));
        },
        showToast: (ToastModel: ToastModel) => dispatch(updateToast(ToastModel)),
    };
};

const mapStateToProps =  (state: StoreState) => {
    const selector = formValueSelector(FORM_NAME)
    let startDate = selector(state, 'start_date')

    if(startDate) {
        try {
            startDate.getTime();
        } catch (error) {
            startDate = new Date(startDate);
        }
    }
    
    return {
        startDate: selector(state, 'start_date'),
        questions: state?.questions,
        enableGeneralRecordingsManagementFlag: state?.icus[0]?.feature_flags?.recordings?.general_recordings_management,
    };
};

interface SchedulerEventFormProps {
    schedulerEvent?: SchedulerEvent;
    addSchedulerEvent: Function;
    updateSchedulerEvent: Function;
    deleteSchedulerEvent: Function;
    showToast: Function;
    onClose: Function;
    enableGeneralRecordingsManagementFlag: boolean;
    startDate?: string;
    questions?: QuestionModel[];
}

export const _SchedulerEventForm = ({
    schedulerEvent,
    addSchedulerEvent,
    updateSchedulerEvent,
    deleteSchedulerEvent,
    showToast,
    onClose,
    enableGeneralRecordingsManagementFlag,
    handleSubmit,
    change,
    initialize,
    submitting,
    startDate,
    dirty,
    questions
}: SchedulerEventFormProps & InjectedFormProps<{}, SchedulerEventFormProps>): JSX.Element => {
    const { t, getTranslatedArray } = useCustomTranslation();
    const [isRepeating, setIsRepeating] = useState<boolean>(false);
    const [communicationType, setCommunicationType] = useState<ActiveCommunicationType>(NONE);
    const [repeatType, setRepeatType] = useState<REPEAT_TYPE>();
    const currentDay = getCurrentDay();
    const currentDate = new Date()
    const [repeatDays, setRepeatDays] = useState<string>(currentDay);
    const [isConfirmChange, setIsConfirmChange] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const { updateArguments  } = React.useContext(DialogContext) as DialogPropTypes;

    const isEdit = !!schedulerEvent
    const isShowDeleteButton = isEdit && !isConfirmChange
    const disableSaveButton = submitting || (isEdit && !dirty && !isDelete)
    const translatedCommunicationTypes = getTranslatedArray(communicationTypes, ['name'])
    const translatedWeekDays = getTranslatedArray(WEEK_DAYS_OPTIONS, ['label'])
    const translatedSlots = getTranslatedArray(SLOTS, ['name'])
    const subOptionConfig: any = {
        [GENERAL_MESSAGE]: {
            label: 'Slot Number', options: translatedSlots, optionValue: 'value', placeholder: 'Select Slot'
        },
        [Q_AND_A]: {
            label: 'Question', options: questions, optionValue: 'id', placeholder: 'Select Question'
        }
    }


    if (enableGeneralRecordingsManagementFlag) {
        translatedCommunicationTypes.push({ name: t("General Messages"), id: 10 });
    }

    useEffect(() => {
        if(schedulerEvent?.id) {
            const initialValues = getInitialValues(schedulerEvent)

            if(!!initialValues.repeat_type && initialValues.repeat_type !== REPEAT_TYPE.NONE) {
                setIsRepeating(true)
                setRepeatType(initialValues.repeat_type)
            }

            if(!!initialValues.repeat_days?.length) setRepeatDays(initialValues.repeat_days)

            setCommunicationType(schedulerEvent.communicationTypeId)
            initialize(initialValues);
        }
    }, [schedulerEvent?.id]);

    const selectedCommunicationTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="create-event-form__dropdown-item-wrapper">
                    <div className="create-event-form__dropdown-icon">
                        <CommunicationIcon communicatoinType={option.id} />
                    </div>
                    <div className="create-event-form__dropdown-text">
                        {option.name}
                    </div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const getRepeatType = (repeatType: REPEAT_TYPE) => {
        setRepeatType(repeatType)  
    }

    const communicationOptionTemplate = (option: any) => {
        return (
            <div className="create-event-form__dropdown-item-wrapper">
                <div className="create-event-form__dropdown-icon">
                    <CommunicationIcon communicatoinType={option.id} />
                </div>
                <div className="create-event-form__dropdown-text">
                    {option.name}
                </div>
            </div>
        );
    };

    const onCommunicationEventChange = (communicationType: any) => {
        setCommunicationType(communicationType);
        change('subOption', '')
    }

    const handleSuccess = (message = '') => {
        showToast({ severity: TOAST_SEVERITY.SUCCESS, summary: t("Success"), detail: t(message) });
    }

    const handleFailure = (message = '') => {
        showToast({ severity: TOAST_SEVERITY.ERROR, summary: t("Failure"), detail: t(message) });
    }

    const onCreateSchedulerEvent = (schedulerObj: any) => {
        return new Promise((resolve) => {
            addSchedulerEvent(
                schedulerObj,
                (result: { status: boolean; error?: string }) => {
                    try {
                        if (!result.status) throw new SubmissionError({_error: result.error});
                        resolve(true);
                        handleSuccess('A new event was successfully created')
                        onClose();
                    } catch (error) {
                        handleFailure('Unable to create a new event. Please try again')
                        console.log(error);
                    }
                }
            );
        });
    }

    const onUpdateSchedulerEvent = (schedulerObj: any) =>{
        return new Promise((resolve) => {
            updateSchedulerEvent(
                schedulerObj,
                (result: { status: boolean; error?: string }) => {
                    try {
                        if (!result.status) throw new SubmissionError({_error: result.error});
                        resolve(true);
                        handleSuccess('Event was successfully edited')
                        onClose();
                    } catch (error) {
                        handleFailure('Unable to edit the event. Please try again')
                        console.log(error);
                    }
                }
            );
        });
    }

    const onDeleteSchedulerEvent = (id: string) =>{
        return new Promise((resolve) => {
            deleteSchedulerEvent(
                id,
                (result: { status: boolean; error?: string }) => {
                    try {
                        if (!result.status) throw new SubmissionError({_error: result.error});
                        resolve(true);
                        handleSuccess('Event was successfully deleted')
                        onClose();
                    } catch (error) {
                        handleFailure('Unable to delete the event. Please try again')
                        console.log(error);
                    }
                }
            );
        });
    }

    const handleOnDelete = () => {
        setIsConfirmChange(true)
        setIsDelete(true)
    }

    const onSubmit = (values: any) => {
        if (values.start_date && values.time) {
            const date = new Date(values.start_date);
            const timeDate = new Date(values.time);
            const formattedDate = `${date.getFullYear()}-${
                String(date.getMonth() + 1).padStart(2, '0')}-${
                String(date.getDate()).padStart(2, '0')}`;
            const formattedTime = `${
                String(timeDate.getHours()).padStart(2, '0')}:${
                String(timeDate.getMinutes()).padStart(2, '0')}:00.000`;
            values.start_date = `${formattedDate}T${formattedTime}`;
        }
         
        if(isDelete) return onDeleteSchedulerEvent(values.id)

        const originalIsRepeating = schedulerEvent?.repeat?.type === REPEAT_TYPE.WEEKLY ||
            schedulerEvent?.repeat?.type === REPEAT_TYPE.DAILY

        if(isEdit && originalIsRepeating && !isConfirmChange) {
            setIsConfirmChange(true)
            return
        }

        const schedulerObj = createSchedulerObj(values, isRepeating, questions || []) as any;

        if (isEdit) {
            return onUpdateSchedulerEvent(schedulerObj)
        }

        return onCreateSchedulerEvent(schedulerObj);
    };

    const handleOnCancel = (event: React.MouseEvent) => {
        event.preventDefault();

        if(isConfirmChange) {
            if(isDelete) setIsDelete(false)
            setIsConfirmChange(false);
            return
        }

        onClose();
    }

    const handleIsRepeatingChange = () => {
        setIsRepeating(prevState => {
            const newState = !prevState

            change('repeat_type', newState ? REPEAT_TYPE.DAILY : REPEAT_TYPE.NONE)

            if(isEdit && isDateInThePast(startDate)) change('start_date', '')

            return newState
        })
    }

    useEffect(() => {
        updateArguments({ title: getTitle(isDelete, isEdit)})
    }, [isDelete])

    useEffect(() => {
        updateArguments({ isClosable: !isConfirmChange})
    }, [isConfirmChange])

    const renderFormContent = () => {
        if (isConfirmChange) {
            return (
                <span className="create-event-form__confirm_message">
                    {isDelete
                        ? "You are about to delete "
                        : "The changes you are about to make will apply to "}all events in the series. Are you sure?
                </span>
            )
        }

        return (
            <>
                <div className="form__row">
                    <div className="form__dropdown">
                        <Field
                            name="communicationTypeId"
                            label={t("Choose Event")}
                            component={MedDropDown}
                            optionLabel="name"
                            optionValue="id"
                            options={translatedCommunicationTypes}
                            valueTemplate={selectedCommunicationTemplate}
                            itemTemplate={communicationOptionTemplate}
                            placeholder={t("Select event")}
                            onChange={onCommunicationEventChange}
                        />
                    </div>
                    {[Q_AND_A, GENERAL_MESSAGE].includes(communicationType) ?
                        <div className="form__dropdown">
                            <Field
                                name="subOption"
                                label={t(subOptionConfig[communicationType].label)}
                                component={MedDropDown}
                                optionLabel="name"
                                optionValue={subOptionConfig[communicationType].optionValue}
                                options={subOptionConfig[communicationType].options}
                                placeholder={t(subOptionConfig[communicationType].placeholder)}
                            />
                        </div> : null
                    }
                </div>
                <div className="form__sub-title">{t("Event Details")}</div>
                <div className="form__row">
                    <div className="form__input">
                        <Field name="start_date" type="date" label={t("Start time")} component={MedCalendar} minDate={currentDate} />
                    </div>
                    <div className="form__input">
                        <Field name="time" type="time" timeOnly component={MedCalendar}  />
                    </div>
                </div>
                <div className="form__sub-title">{t("Event Details")}</div>
                <div className="form__row">
                    <div className="create-event__repeat-checkbox-wrapper med-checkbox">
                        <Checkbox
                            onChange={handleIsRepeatingChange}
                            checked={isRepeating}
                            name="create-event__repeat-checkbox"
                        />
                        <label
                            htmlFor="create-event__repeat-checkbox"
                            className="create-event__checbox-label p-checkbox-label"
                        >
                            {t("Repeating Event")}
                        </label>
                    </div>
                </div>
                {isRepeating ? (
                    <div className="form__row-wrapper">
                        <div className="form__row">
                            <Field
                                name="repeat_type"
                                component={MedRadioButtons}
                                options={repeatOptions}
                                getUpdatedValue={getRepeatType}
                            />
                        </div>
                        {repeatType === REPEAT_TYPE.WEEKLY ? (
                            <div className="form__row form__row--repeat-days">
                                <Field
                                    name="repeat_days"
                                    component={MedCheckboxes}
                                    options={translatedWeekDays}
                                    checkedValues={repeatDays}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </>
        )
    }

    return (
        <div className={`form create-event-form${isConfirmChange ? '__confirm' : ''}`}>
            <form onSubmit={handleSubmit(onSubmit)} className="create-event__field-set form">
                <div className="form__conatainer">
                    {renderFormContent()}
                    <div className={`form__footer${isShowDeleteButton ? ' buttons-block'  :''} `}>
                        {isShowDeleteButton ? <Button
                            label={t("Delete event")}
                            className="form__footer__cancel-button p-button-outlined-2 button-cancel button button-form button-wide"
                            onClick={handleOnDelete}
                        /> : null}
                        <div className="flex right-part">
                            <Button
                                label={t("Cancel")}
                                className="form__footer__cancel-button p-button-outlined-2 button-cancel button button-form"
                                onClick={handleOnCancel}
                            />
                            <Button
                                type="submit"
                                className={`form__save-button button button-form ${submitting ? "button-disabled" : ""}`}
                                disabled={disableSaveButton}
                            >
                                {submitting ? (
                                    <div className="button-loading">
                                        <ProgressSpinner />
                                    </div>
                                ) : (
                                    t("Save")
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const componentWithForm = reduxForm<{}, SchedulerEventFormProps>({
    form: FORM_NAME,
    validate: validateForm,
})(_SchedulerEventForm);

export const SchedulerEventForm = connect(mapStateToProps, mapDispatchToProps)(componentWithForm);
