import {Repeat, REPEAT_TYPE, SchedulerEvent, WEEK_DAYS} from "src/models/scheduler.model";
import moment from "moment/moment";
import {ActiveCommunicationType, mapCommunicationTypeToString} from "src/models/device.model";
import { QuestionModel } from "src/models/question.model";
import { SLOT_TYPES } from "./constants";

const {GENERAL_MESSAGE, MUSIC, Q_AND_A} = ActiveCommunicationType

export const validateForm = (values: any) => {
    const errors: any = {};

    if (!values.communicationTypeId) {
        errors.communicationTypeId = "Type is a mandatory field";
    }

    if (!values.subOption) {
        //TODO: a message to support different sub option
        errors.subOption = "Options is a mandatory field";
    }

    if (!values.time) {
        errors.time = "Time is a mandatory field";
    }

    if (!values.start_date) {
        errors.start_date = "Start Date is a mandatory field";
    }

    if (!values.repeat_days) {
        errors.repeat_days = "Please select a day";
    }

    return errors;
};

interface FormValues {
    communicationTypeId: ActiveCommunicationType
    subOption?: number | string
    repeat_type?: REPEAT_TYPE,
    repeat_days?: string,
    start_date: Date,
    time: Date,
    id?: string
}

export const createSchedulerObj = (formValues: FormValues, isRepeating: boolean, questions: QuestionModel[]) => {
    const repeatObj: Repeat = { isActive: false, days: [], endDate: null, type: REPEAT_TYPE.NONE } as Repeat;

    if (isRepeating) {
        repeatObj.isActive = true;

        if (formValues.repeat_type === REPEAT_TYPE.WEEKLY) {
            repeatObj.type = REPEAT_TYPE.WEEKLY;
            if(formValues.repeat_days) repeatObj.days = (formValues.repeat_days).split(",") as WEEK_DAYS[];
        }

        if (formValues.repeat_type === REPEAT_TYPE.DAILY) {
            repeatObj.type = REPEAT_TYPE.DAILY;
        }

        // TODO: create real date.
        repeatObj.endDate = moment().add(7, "years").toDate();
    }

    let schedulerEvent: Partial<SchedulerEvent> = {
        text: mapCommunicationTypeToString(formValues.communicationTypeId),
        subType: "",
        communicationTypeId: formValues.communicationTypeId,
        repeat: repeatObj,
        startDate: formValues.start_date,
        time: moment(formValues.time).format("HH:mm"),
    };

    if (formValues.subOption) schedulerEvent.subOption = formValues.subOption;
    if (formValues.id) schedulerEvent.id = formValues.id;

    if (formValues.communicationTypeId === GENERAL_MESSAGE) {
        if(formValues.subOption && formValues.subOption as number < 0) {
            const slotType = SLOT_TYPES[formValues.subOption.toString()];
            schedulerEvent.text = `${slotType} ${schedulerEvent.text}`.trim();
        } else {
            schedulerEvent.text += ` ${formValues.subOption}`
        }
    }

    if (formValues.communicationTypeId === Q_AND_A) {
        schedulerEvent.text = questions.find(
            (questino) => questino.id === formValues.subOption
        )?.name;
    }

    if (schedulerEvent.communicationTypeId === MUSIC) {
        //TODO add personal music option
        schedulerEvent = {
            ...schedulerEvent,
            genre: "radio_uk",
        };
    }

    return schedulerEvent;
};


export const getInitialValues = (schedulerEvent: SchedulerEvent) => {
    let timestamp = (schedulerEvent.startDate as unknown as number);
    if(typeof schedulerEvent.startDate === "string") {
        timestamp = new Date(schedulerEvent.startDate).getTime();
    }
    const startDate = new Date(isTimestampInSeconds(timestamp) ? timestamp * 1000 : timestamp);
    const [hours, minutes] = (schedulerEvent.time as string).split(':');
    
    startDate.setHours(parseInt(hours));
    startDate.setMinutes(parseInt(minutes));

    const initialValues: FormValues = {
        communicationTypeId: schedulerEvent.communicationTypeId,
        repeat_days: schedulerEvent.repeat.days.join(','),
        repeat_type: schedulerEvent.repeat.type,
        start_date: startDate,
        time: startDate
    }

    if(schedulerEvent.subOption) initialValues.subOption = schedulerEvent.subOption;
    if(schedulerEvent.id) initialValues.id = schedulerEvent.id;

    return initialValues
}

export const getCurrentDay = (): string => moment().format("dddd");

export const isDateInThePast = (date = '') => {
    if(!date) return false

    const givenDate = moment(date);
    const currentDate = moment();

    return givenDate.isBefore(currentDate, 'day')
}

export const getTitle = (isDelete = false, isEdit = false) => {
    if(isDelete) return 'Delete Event';
    if(isEdit) return 'Edit Event';

    return 'Add New Event'
}

const isTimestampInSeconds = (timestamp: number): boolean => {
    return timestamp < 999999999999;  // Timestamps in seconds before year 2001
};